import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

type Props = {
  totalNumber: number
  roomNumber: string
  onClose: () => void
}
export const RoomToIssueKeyModal: React.FC<Props> = props => {
  const { t } = useTranslation()
  return (
    <>
      <div css={modalBgStyle} onClick={props.onClose}></div>
      <div css={modalStyle} onClick={props.onClose}>
        <div className="issue-announce-text">{t('SelfCheckIn.CardIssueAnnouncement', { n: props.totalNumber })}</div>
        <div className="room-block">
          <div className="room-number-label">{t('Room number')}</div>
          <div className="room-number-text">{props.roomNumber}</div>
        </div>
      </div>
    </>
  )
}

const modalBgStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 100,
  background: '#000000',
  opacity: 0.5,
  width: '100vw',
  height: '100vh',
})
const modalStyle = css({
  position: 'absolute',
  zIndex: 1000,
  background: '#ffffff',
  borderRadius: 5,
  width: 600,
  height: 'min-content',
  margin: 'auto',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  lineHeight: 1.5,
  padding: 40,
  textAlign: 'center',
  '.issue-announce-text': {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#272727',
    letterSpacing: 3.6,
  },
  '.room-block': {
    marginTop: 24,
    padding: 32,
    background: '#F5F5F5',
    border: '2px solid #CCCCCC',
    borderRadius: 10,
    '.room-number-label': {
      fontSize: 21,
      fontWeight: 'bold',
      color: '#676767',
      letterSpacing: 2.1,
    },
    '.room-number-text': {
      fontSize: 61,
      fontWeight: 'bold',
      color: '#F2A40B',
      letterSpacing: 9.15,
    },
  },
})
