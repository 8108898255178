import React, { useContext, useEffect, useState } from 'react'
import { SettingTab } from '@/components/molecules/settings/setting-tab'
import { listContainerStyle } from '@/constants/layout'
import { css } from '@emotion/core'
import * as pluginGetter from '@/libs/plugins'
import { AccountContext } from '@/contexts/account'

export const CheckinTabContainer: React.FC<{ currentTab: 'Self check-in' | 'Custom' | 'Card reader' }> = ({ currentTab, children }) => {
  const { plugins } = useContext<any>(AccountContext)
  const [tabs, setTabs] = useState<Array<{ name: string; path: string }>>([])
  const hasCustomCheckinPlugin = pluginGetter.setHasCustomCheckinPlugin(plugins)
  const hasSelfCheckinPlugin = pluginGetter.setHasSelfCheckinPlugin(plugins)

  useEffect(() => {
    const enableTabs: Array<{ name: string; path: string }> = []
    if (hasSelfCheckinPlugin) {
      enableTabs.push({
        name: 'Self check-in',
        path: '/setting/checkin/self-checkin',
      })
    }
    if (hasCustomCheckinPlugin) {
      enableTabs.push({
        name: 'Custom',
        path: '/setting/checkin/custom-checkin',
      })
    }
    enableTabs.push({
      name: 'Card reader',
      path: '/setting/checkin/card-reader',
    })
    setTabs(enableTabs)
  }, [hasCustomCheckinPlugin, hasSelfCheckinPlugin])

  return (
    <SettingTab currentTab={currentTab} tabs={tabs} customTabStyle={css({ width: 160 })}>
      <div css={topImageContainerStyle}>{children}</div>
    </SettingTab>
  )
}

const topImageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 'auto',
  marginBottom: -10,
})
